// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-disclosures-tsx": () => import("./../../../src/pages/disclosures.tsx" /* webpackChunkName: "component---src-pages-disclosures-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-blog-index-tsx": () => import("./../../../src/templates/blog/index.tsx" /* webpackChunkName: "component---src-templates-blog-index-tsx" */),
  "component---src-templates-blog-post-index-tsx": () => import("./../../../src/templates/blogPost/index.tsx" /* webpackChunkName: "component---src-templates-blog-post-index-tsx" */),
  "component---src-templates-client-access-index-tsx": () => import("./../../../src/templates/ClientAccess/index.tsx" /* webpackChunkName: "component---src-templates-client-access-index-tsx" */),
  "component---src-templates-get-in-touch-index-tsx": () => import("./../../../src/templates/GetInTouch/index.tsx" /* webpackChunkName: "component---src-templates-get-in-touch-index-tsx" */),
  "component---src-templates-how-we-help-index-tsx": () => import("./../../../src/templates/HowWeHelp/index.tsx" /* webpackChunkName: "component---src-templates-how-we-help-index-tsx" */),
  "component---src-templates-who-we-are-index-tsx": () => import("./../../../src/templates/WhoWeAre/index.tsx" /* webpackChunkName: "component---src-templates-who-we-are-index-tsx" */),
  "component---src-templates-who-we-serve-index-tsx": () => import("./../../../src/templates/WhoWeServe/index.tsx" /* webpackChunkName: "component---src-templates-who-we-serve-index-tsx" */)
}

