import React from "react"
import "@styles/index.scss"
import "./index.scss"
import { graphql, useStaticQuery } from "gatsby"
import Header from "./components/header"
import FooterComponent from "./components/footer"

const Layout = ({ children }) => {
  const { sanityNavigation } = useStaticQuery(query)

  return (
    <>
      <main>
        <Header navigation={sanityNavigation} />
        <div className="layout">{children}</div>
        <FooterComponent navigation={sanityNavigation} eventKey="50" />
      </main>
    </>
  )
}

const query = graphql`
  query {
    sanityNavigation {
      content {
        title
        slug {
          current
        }
        showInFooter
        showInHeader
        childs {
          title
          slug {
            current
          }
        }
      }
      title
      _rawContactInfo(resolveReferences: { maxDepth: 10 })
      ctaButton {
        buttonType
        linkUrl
        title
      }
    }
  }
`

export default Layout
