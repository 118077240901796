import BlockContent from "@sanity/block-content-to-react"
import Image from "gatsby-image"
import React from "react"
import { SanityRawText } from "./globalTypes"
import { getFluidGatsbyImage } from "gatsby-source-sanity"
import CtaButton from "@components/CtaButton"
import BackgroundImage from "gatsby-background-image"
import "./richText.scss"

import { Link } from "gatsby"

const {
  GATSBY_SANITY_PROJECT_ID = "9js02fjq",
  GATSBY_SANITY_DATASET = "production",
} = process.env

const Figure = ({ node }) => {
  const fluidProps = getFluidGatsbyImage(
    node.asset._id,
    { maxWidth: 875 },
    { dataset: GATSBY_SANITY_DATASET, projectId: GATSBY_SANITY_PROJECT_ID }
  )

  return (
    <figure>
      <Image fluid={fluidProps} alt={node.alt || "birchbrook"} />
    </figure>
  )
}

const FigureWithCTA = ({ node }) => {
  const fluidProps = getFluidGatsbyImage(
    node.image.asset._id,
    { maxWidth: 875 },
    { dataset: GATSBY_SANITY_DATASET, projectId: GATSBY_SANITY_PROJECT_ID }
  )

  return (
    <figure>
      <BackgroundImage
        Tag="div"
        fluid={fluidProps}
        backgroundColor={`#ded9e5`}
        alt={node.image.alt}
        className="hovered-image"
      >
        <div className="cta-image">
          {node.ctaButton && (
            <div className="hovered-imageButton">
              <CtaButton ctaButton={node.ctaButton} />
            </div>
          )}
        </div>
      </BackgroundImage>
    </figure>
  )
}

export const defaultSerializers = {
  marks: {
    italic: (props) => <em>{props.children}</em>,
    link: ({ mark, children }) => {
      const { href } = mark
      const docTypes = [".pdf", ".doc", ".pages", ".ppt"]
      const isDoc = docTypes.find((str) => href?.includes(str))
      return href && href?.charAt(0) === "/" && !isDoc ? (
        <Link to={href} className="link">
          {children}
        </Link>
      ) : (
        <a href={href} target="_blank" rel="noopener" className="link">
          {children}
        </a>
      )
    },
    code: (props) => (
      <code dangerouslySetInnerHTML={{ __html: props.children }} />
    ),
    color: (props) => (
      <span style={{ color: props.mark.hex }}>{props.children}</span>
    ),
    highlight: (props) => <mark>{props.children}</mark>,
    AlignCenter: (props) => (
      <div style={{ textAlign: "center" }}>{props.children}</div>
    ),
    AlignJustify: (props) => (
      <div style={{ textAlign: "justify" }}>{props.children}</div>
    ),
    AlignLeft: (props) => (
      <div style={{ textAlign: "left" }}>{props.children}</div>
    ),
    AlignRight: (props) => (
      <div style={{ textAlign: "right" }}>{props.children}</div>
    ),
  },
  types: {
    /*code: (props) => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    )*/
    code: (props) => (
      <code dangerouslySetInnerHTML={{ __html: props.children }} />
    ),
    italic: ({ node }) => <em>{node.text}</em>,
    reference: () => <div></div>,
    mainImage: Figure,
    imageWithCTA: FigureWithCTA,
    ctaButton: ({ node }) => (
      <div className="rich-text-ctaButtonContainer">
        {" "}
        <CtaButton ctaButton={node} />{" "}
      </div>
    ),
  },
}

type Props = {
  blocks: SanityRawText[]
  serializers?
  className?: string
}

export const BaseBlockContent: React.FC<Props> = ({
  blocks,
  serializers,
  className,
}) => {
  const serializersDict = serializers || defaultSerializers
  return (
    <BlockContent
      serializers={serializersDict}
      blocks={blocks}
      className={`block-content ${className}`}
    />
  )
}
