import React from "react"
import { Link } from "gatsby"
import { CtaButton } from "@utils/globalTypes"
import "./index.scss"

type Props = {
  ctaButton: CtaButton
  onClick?: any
}

const CtaButtonComponent = ({ ctaButton, ...rest }: Props) => {
  const { title, buttonType, linkUrl } = ctaButton

  return (
    <Link
      className={`${buttonType.toLowerCase()} ${
        buttonType.toLowerCase() === "navigation" ? "" : "cta"
      } `}
      to={linkUrl}
      {...rest}
    >
      {title}
    </Link>
  )
}

export default CtaButtonComponent
