import React, { useContext, useState } from "react"
import "./index.scss"
import { NavigationType } from "@utils/globalTypes"
import { Navbar, Nav, Accordion, AccordionContext, useAccordionButton, Row } from "react-bootstrap"
import Image from "react-bootstrap/Image"
import { Link } from "gatsby"
import brandLigth from "@assets/svg/WordMark_lavender.svg"
import Hamburger from "@assets/svg/hamburger"
import PurpleCarrot from "@assets/svg/Purple_carrot_mobile nav"
import CtaButtonComponent from "@components/CtaButton"
import useResizeWidth from "@hooks/useResizeWidth"

type Props = {
  navigation: NavigationType
}

type ToogleProps = {
  eventKey: string
  callback?: any
  title: string
  link?: string
  onLinkClicked?: () => any
}

const isBrowser = typeof window !== "undefined"

const HeaderComponent = ({ navigation }: Props) => {
  const [expanded, setExpanded] = useState(false)
  const { width } = useResizeWidth()
  const [active, setActive] = useState("")
  const { content, ctaButton } = navigation
  const expand = isBrowser && width <= 961 ? true : false

  /**
   * Toggle functionality for the use of accordion icons
   */

  const CustomToggle = ({ eventKey, callback, title, link, onLinkClicked }) => {
    const { activeEventKey } = useContext(AccordionContext)
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      callback && activeEventKey === eventKey ? callback("") : callback(eventKey),
    )
    const isCurrentEventKey = activeEventKey === eventKey

    return (
      <Accordion.Button
        type="button"
        onClick={decoratedOnClick}
        style={{ color: "#bd9fc3" }}
        className={`nav-link navItem ${isCurrentEventKey ? "rotate-carrot" : ""}`}
      >
        <Link to={link} onClick={onLinkClicked} style={{ background: "#090d31" }} className="navItem ">
          {title}
        </Link>
        <PurpleCarrot />
      </Accordion.Button>
    )
  }

  const handleLinkSelected = () => {
    setExpanded(false)
    setActive("")
  }

  return (
    <Navbar
      expand={!expand}
      expanded={expanded}
      fixed="top"
      className={`navbar ${
        `/${window?.location?.pathname?.split("/")[1]}` === ctaButton.linkUrl ? "active-nav-ctaButton" : ""
      }`}
      onToggle={() => setExpanded(!expanded)}
      collapseOnSelect
    >
      <div className="navbar-container">
        {width < 960 ? (
          <div className="head-container">
            <Navbar.Brand as={Link} to="/">
              <Image src={brandLigth} className="header-brand" alt="birchbrook" />
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="navbarScroll" style={{ color: "#ded9e5" }}>
              {expanded ? <span className="close-toggle">X</span> : <Hamburger />}
            </Navbar.Toggle>
          </div>
        ) : (
          <>
            {" "}
            <Navbar.Brand as={Link} to="/">
              <Image src={brandLigth} className="header-brand" alt="birchbrook" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" style={{ color: "#ded9e5" }}>
              {expanded ? <span className="close-toggle">X</span> : <Hamburger />}
            </Navbar.Toggle>
          </>
        )}
        <Navbar.Collapse className="navbar-collapse" id="navbarScroll">
          <Nav className="ml-auto items navbar-nav-scroll">
            {content && width > 961 ? (
              <Row noGutters className="hd-links-row">
                {content.map(({ slug: { current }, showInHeader, title, childs }, index) =>
                  showInHeader ? (
                    <Nav.Item className={`navItem`} key={index}>
                      <Link
                        to={current === "/client-access" || current === "/how-we-help" ? "./" : `${current}`}
                        style={{ color: "#ded9e5" }}
                        activeClassName={
                          (current === "/client-access" || current === "/how-we-help") &&
                          location.pathname != "/client-access/"
                            ? location.pathname.indexOf("how-we-help") === -1
                              ? ""
                              : "active-navbar-item"
                            : "active-navbar-item"
                        }
                        partiallyActive
                        className={`navItem`}
                      >
                        {title}
                      </Link>
                      {childs.length > 0 && (
                        <div className="header-menu">
                          {childs?.map((link, index) =>
                            link?.slug?.current.search(/(http(s)?):\/\/(www\.)*/gi) > -1 ? (
                              <a key={index} href={link?.slug?.current} rel="noopener" target="_blank">
                                {link?.title}
                              </a>
                            ) : (
                              <Link
                                key={index}
                                to={`${current}${link?.slug?.current}`}
                                className={`${
                                  `#${window?.location?.href.split("#").pop()}` === link?.slug?.current
                                    ? "active-navbar-item"
                                    : `/${window?.location?.href.split("/").pop()}` === link?.slug?.current
                                    ? "active-navbar-item"
                                    : ""
                                }`}
                              >
                                {link?.title}
                              </Link>
                            ),
                          )}
                        </div>
                      )}
                    </Nav.Item>
                  ) : null,
                )}
              </Row>
            ) : (
              <Accordion activeKey={active} as={"div"}>
                {content.map(
                  ({ slug: { current }, showInHeader, title, childs }, index) =>
                    showInHeader && (
                      <div
                        className={
                          active === `${index + 1}` ? "ht-expanded header-link-container" : "header-link-container"
                        }
                        key={index}
                      >
                        {childs?.length > 0 ? (
                          <>
                            {/*        <Toogle
                              eventKey={`${index + 1}`}
                              title={title}
                              link={current === "/client-access" || current === "/how-we-help" ? "./" : `${current}`}
                              callback={setActive}
                              onLinkClicked={
                                current === "/client-access" || current === "/how-we-help"
                                  ? () => setExpanded(true)
                                  : handleLinkSelected
                              }
                            />*/}

                            <CustomToggle
                              eventKey={`${index + 1}`}
                              title={title}
                              link={current === "/client-access" || current === "/how-we-help" ? "./" : `${current}`}
                              callback={setActive}
                              onLinkClicked={
                                current === "/client-access" || current === "/how-we-help"
                                  ? () => setExpanded(true)
                                  : handleLinkSelected
                              }
                            />

                            <Accordion.Collapse eventKey={`${index + 1}`}>
                              <div className="hd-links-container">
                                {childs?.map((link, index) =>
                                  link?.slug?.current.search(/(http(s)?):\/\/(www\.)*/gi) > -1 ? (
                                    <a
                                      key={index}
                                      onClick={handleLinkSelected}
                                      href={`${link?.slug?.current}`}
                                      rel="noopener"
                                      target="_blank"
                                      className="header-link"
                                    >
                                      {link?.title}
                                    </a>
                                  ) : (
                                    <Link
                                      key={index}
                                      onClick={handleLinkSelected}
                                      to={`${current}${link?.slug?.current}`}
                                      className="header-link"
                                    >
                                      {link?.title}
                                    </Link>
                                  ),
                                )}
                              </div>
                            </Accordion.Collapse>
                          </>
                        ) : (
                          <div className="navItem nav-link" key={index}>
                            {" "}
                            <Link to={current} className="navItem" onClick={handleLinkSelected}>
                              {title}
                            </Link>
                          </div>
                        )}
                      </div>
                    ),
                )}
                {ctaButton && (
                  <div className={`hd-links-container`}>
                    <CtaButtonComponent onClick={handleLinkSelected} ctaButton={ctaButton} />
                  </div>
                )}
              </Accordion>
            )}
          </Nav>
        </Navbar.Collapse>
        {ctaButton && width > 961 && <CtaButtonComponent ctaButton={ctaButton} />}
      </div>
    </Navbar>
  )
}

export default HeaderComponent
