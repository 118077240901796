import React, { useContext, useState } from "react"
import "./index.scss"
import { NavigationType } from "@utils/globalTypes"
import brandLigth from "@assets/svg/WordMark_lavender.svg"
import CreamCarrot from "@assets/svg/Cream_carrot_mobile footer"
import PurpleCarrot from "@assets/svg/Purple_carrot_mobile nav"
import Image from "react-bootstrap/Image"
import { Container, Row, Col, Accordion, AccordionContext, useAccordionButton } from "react-bootstrap"
import { BaseBlockContent } from "@utils/richText"
import { Link } from "gatsby"
import useResizeWidth from "@hooks/useResizeWidth"
import AccordionCarrot from "@assets/svg/AccordionCarrot"
import Card from "react-bootstrap/Card"

type Props = {
  navigation: NavigationType
  eventKey: string
}

type ToogleProps = {
  eventKey: string
  callback?: any
  title: string
}

const FooterComponent = ({ navigation, eventKey }: Props) => {
  const { _rawContactInfo, content, ctaButton } = navigation
  const { width } = useResizeWidth()

  /**
   * Toggle functionality for the use of accordion icons
   */
  const CustomToggle = ({ eventKey, callback, title }) => {
    const { activeEventKey } = useContext(AccordionContext)
    const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey))
    const isCurrentEventKey = activeEventKey === eventKey

    return (
      <button
        type="button"
        onClick={decoratedOnClick}
        style={{ background: "#090d31" }}
        className={`ft-title ${isCurrentEventKey ? "rotate-carrot" : ""}`}
      >
        {title} <CreamCarrot />
      </button>
    )
  }

  const renderColumns = () => {
    const grid = []
    const columns = content.filter((item) => {
      if (item.showInFooter) {
        if (item.childs.length < 7) {
          grid.push(item)
          return false
        } else {
          return true
        }
      }
    })
    return (
      <>
        {" "}
        {columns.map((item, index) => (
          <Col className="ft-links-container" key={index}>
            <div>
              <h4 className="ft-title">{item?.title}</h4>
              {item?.childs?.map((link, index) =>
                link?.slug?.current.search(/(http(s)?):\/\/(www\.)*/gi) > -1 ? (
                  <a href={`${link?.slug?.current}`} rel="noopener" target="_blank" className="ft-link" key={index}>
                    {link?.title}
                  </a>
                ) : (
                  <Link to={`${item?.slug?.current}${link?.slug?.current}`} className="ft-link" key={index}>
                    {link?.title}
                  </Link>
                ),
              )}
            </div>
          </Col>
        ))}
        <Col className="ft-grid" key={columns.length}>
          <Row>
            {grid.map((item, index) => (
              <Col className="ft-links-container-grid" key={columns.length + index + 1} lg={6}>
                <div>
                  <h4 className="ft-title">{item?.title}</h4>
                  {item?.childs?.length > 0 ? (
                    item?.childs?.map((link, index) =>
                      link?.slug?.current.search(/(http(s)?):\/\/(www\.)*/gi) > -1 ? (
                        <a
                          href={`${link?.slug?.current}`}
                          rel="noopener"
                          target="_blank"
                          className="ft-link"
                          key={index}
                        >
                          {link?.title}
                        </a>
                      ) : (
                        <Link to={`${item?.slug?.current}${link?.slug?.current}`} className="ft-link" key={index}>
                          {link?.title}
                        </Link>
                      ),
                    )
                  ) : item?.slug?.current.search(/(http(s)?):\/\/(www\.)*/gi) > -1 ? (
                    <a href={`${item?.slug?.current}`} rel="noopener" target="_blank" className="ft-link" key={index}>
                      {item?.title}
                    </a>
                  ) : (
                    <Link to={`${item?.slug?.current}`} className="ft-link" key={index}>
                      {item?.title}
                    </Link>
                  )}
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </>
    )
  }

  return (
    <Container id="footer" className="ft-container footer">
      {width < 961 ? (
        <Col>
          <Col className="ft-links-container">
            <Link to="/">
              <Image src={brandLigth} className="brand" alt="birchbrook" />
            </Link>
          </Col>

          <Accordion>
            {_rawContactInfo && (
              <Col className="ft-links-container">
                <CustomToggle eventKey="0" title={ctaButton.title} />

                <Accordion.Collapse eventKey="0" style={{ paddingLeft: "9px" }}>
                  <div>
                    <BaseBlockContent blocks={_rawContactInfo} className="contact-info phone-class" />
                    <Link to={ctaButton.linkUrl} className="ft-button ft-button-underlined">
                      Contact us
                    </Link>
                    <Link to={`/disclosures`} className="ft-link">
                      Disclosures
                    </Link>
                  </div>
                </Accordion.Collapse>
              </Col>
            )}

            {content &&
              content.map((item, index) =>
                item.showInFooter ? (
                  <Col className="ft-links-container" key={index}>
                    {/*Adding the custom toggle*/}
                    <CustomToggle eventKey={`${index + 1}`} title={item.title}>
                      {<h5>{item.title}</h5>}{" "}
                    </CustomToggle>

                    <Accordion.Collapse eventKey={`${index + 1}`} style={{ paddingLeft: "9px" }}>
                      <div>
                        {item?.childs?.length > 0 ? (
                          item?.childs?.map((link, index) =>
                            link?.slug?.current.search(/(http(s)?):\/\/(www\.)*/gi) > -1 ? (
                              <a
                                key={index}
                                href={`${link?.slug?.current}`}
                                rel="noopener"
                                target="_blank"
                                className="ft-link"
                              >
                                {link?.title}
                              </a>
                            ) : (
                              <Link key={index} to={`${item?.slug?.current}${link?.slug?.current}`} className="ft-link">
                                {link?.title}
                              </Link>
                            ),
                          )
                        ) : item?.slug?.current.search(/(http(s)?):\/\/(www\.)*/gi) > -1 ? (
                          <a
                            key={index}
                            href={`${item?.slug?.current}`}
                            rel="noopener"
                            target="_blank"
                            className="ft-link"
                          >
                            {item?.title}
                          </a>
                        ) : (
                          <Link key={index} to={`${item?.slug?.current}`} className="ft-link">
                            {item?.title}
                          </Link>
                        )}
                      </div>
                    </Accordion.Collapse>
                  </Col>
                ) : (
                  <></>
                ),
              )}
          </Accordion>
        </Col>
      ) : (
        <Row>
          <Col className="ft-brand-container">
            <Col>
              <Link to="/">
                <Image src={brandLigth} className="brand" alt="birchbrook" />
              </Link>
            </Col>
          </Col>
          <Col className="ft-links-container">
            {_rawContactInfo && (
              <Col>
                <h4 className="ft-title">{ctaButton?.title}</h4>

                <BaseBlockContent blocks={_rawContactInfo} className="contact-info phone-class" />

                <Link to={ctaButton.linkUrl} className="ft-button">
                  Contact us
                </Link>
                <Link to={`/disclosures`} className="ft-link">
                  Disclosures
                </Link>
              </Col>
            )}
          </Col>
          {renderColumns()}
        </Row>
      )}
    </Container>
  )
}

export default FooterComponent
