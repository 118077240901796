import React from "react"

const CreamCarrot = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.701"
      height="21.156"
      viewBox="0 0 12.701 21.156"
    >
      <g
        id="Group_233"
        data-name="Group 233"
        transform="translate(1.061 1.061)"
      >
        <g
          id="Group_46"
          data-name="Group 46"
          transform="translate(0 19.035) rotate(-90)"
        >
          <path
            id="Path_48"
            data-name="Path 48"
            d="M0,0,9.518,9.519,19.035,0"
            fill="none"
            stroke="#fff5eb"
            strokeWidth="3"
          />
        </g>
      </g>
    </svg>
  )
}

export default CreamCarrot
