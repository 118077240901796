import React from "react"

const Hamburger = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="22"
      viewBox="0 0 29 22"
    >
      <g id="Group_197" data-name="Group 197" transform="translate(0 2)">
        <g id="Group_196" data-name="Group 196">
          <line
            id="Line_97"
            data-name="Line 97"
            x2="29"
            fill="none"
            stroke="#ded9e5"
            strokeWidth="4"
          />
          <line
            id="Line_98"
            data-name="Line 98"
            x2="29"
            transform="translate(0 9)"
            fill="none"
            stroke="#ded9e5"
            strokeWidth="4"
          />
          <line
            id="Line_99"
            data-name="Line 99"
            x2="29"
            transform="translate(0 18)"
            fill="none"
            stroke="#ded9e5"
            strokeWidth="4"
          />
        </g>
      </g>
    </svg>
  )
}

export default Hamburger
